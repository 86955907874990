import ReactDOM from "react-dom";
import React from "react";
import { AuthenticationApp } from "@circle-react/apps/AuthenticationApp";

(function() {
  // If on production we find that this approach is impacting our load times then we can resort to using
  // Simple JS without react.
  const element = document.getElementById("react-root");
  const props = JSON.parse(element.dataset.props) || {};
  ReactDOM.render(<AuthenticationApp {...props} />, element);
})();
